const DATA_TEST_IDS = {
  MAIN_MENU: {
    MY_TEAM: {
      ROOT: 'menu__my-team',
    },
  },
  TEAMS: {
    IMPORT: {
      BUTTON: 'teams__import-button',
      MODAL: {
        BODY: 'teams__import-modal-body',
        FILE_UPLOAD: {
          ROOT: 'teams__import-modal-file-upload-button',
        },
        BUTTONS: {
          CSV_IMPORT: 'teams__import-modal-import-emails-button',
          ADD_USER: 'teams__import-modal-add-user-button',
          CSV_TEMPLATE_DOWNLOAD:
            'teams__import-modal-csv-template-download-button',
        },
      },
    },
  },
  LOGIN: {
    SIGN_UP_BUTTON: 'sign-up-button',
    EMAIL: {
      INPUT: 'login__email-input',
    },
    PASSWORD: {
      INPUT: 'login__password-input',
    },
    SUBMIT: {
      BUTTON: 'login__submit-button',
    },
  },
  SIGN_UP: {
    FORM: 'sign-up-form',
    EMAIL: {
      ROOT: 'sign-up__email',
      INPUT: 'sign-up__email-input',
    },
    FIRST_NAME: {
      ROOT: 'sign-up__firstName',
      INPUT: 'sign-up__firstName-input',
    },
    LAST_NAME: {
      ROOT: 'sign-up__lastName',
      INPUT: 'sign-up__lastName-input',
    },
    COMPANY_NAME: {
      ROOT: 'sign-up__companyName',
      INPUT: 'sign-up__companyName-input',
    },
    PASSWORD: {
      ROOT: 'sign-up__password',
      INPUT: 'sign-up__password-input',
    },
    T_AND_C: {
      ROOT: 'sign-up__TandC',
      CHECKBOX: 'sign-up__TandC-checkbox',
    },
    REMEMBER_ME: {
      ROOT: 'sign-up__rememberMe',
      CHECKBOX: 'sign-up__rememberMe-checkbox',
    },
    SUBMIT: {
      ROOT: 'sign-up__createAccount',
      BUTTON: 'sign-up__createAccount-button',
    },
    ERRORS: {
      ROOT: 'sign-up__errors',
    },
  },
};

export { DATA_TEST_IDS };
