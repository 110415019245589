export const getYouCanBookMeParams = ({
  fullName,
  companyName,
  email,
  category,
}: {
  fullName?: string;
  companyName?: string;
  email?: string;
  category?: any;
}) => {
  return {
    NAME: fullName,
    COMPANY: companyName,
    EMAIL: email,
    CATEGORY: category,
  };
};
